import React, { useState } from 'react';
import style from './simpleRecipeList.module.css';
import RecipeModal from './RecipeModal';

const SimpleRecipeList = ({ recipes }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const openModal = (recipe) => {
        setSelectedRecipe(recipe);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedRecipe(null);
        setModalIsOpen(false);
    };

    return (
        <div className={style.page}>
            <div className={style.title}> RECIPES </div>
            {recipes && recipes.map((recipeData, index) => {
                const recipe = recipeData.recipe;
                return (
                    <div className={style.recipe} key={index}>
                        <h2> {recipe.label} </h2>
                        <img src={recipe.image} alt={recipe.label} />
                        <h2> <u>Ingredients</u> </h2>
                        <h4> {recipe.ingredientLines.map((item, idx) => (
                            <div key={idx}>
                                <h3> {Capitalize(item)} </h3>
                            </div>
                        ))} </h4>
                        <button onClick={() => openModal(recipe)}>Calculate Health Score</button>
                    </div>
                );
            })}
            {selectedRecipe && (
                <RecipeModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    recipe={selectedRecipe}
                />
            )}
        </div>
    );
}

export default SimpleRecipeList;
