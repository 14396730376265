import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import config from './config';
import Recipe from "./Recipe";
import './App.css';
import About from './About';
import Drinks from './Drink';
import Home from './Home';
import ItemDetail from './ItemDetail';

const App = () => {
  // Remove APP_ID and APP_KEY from here
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    // Initialize Google Analytics
    const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-2LX8DFQYPN';
    if (process.env.REACT_APP_GA_ENABLED === 'true') {
      ReactGA.initialize(MEASUREMENT_ID);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      console.log('Google Analytics initialized with ID:', MEASUREMENT_ID);
    } else {
      console.log('Google Analytics is disabled');
    }
  }, []);

  useEffect(() => {
    getRecipes();
  }, [query]);

  const APP_ID = config.APP_ID || '900da95e';
  const APP_KEY = config.APP_KEY || '40698503668e0bb3897581f4766d77f9';
  const REQUEST_STR = `https://api.edamam.com/search?app_id=${APP_ID}&app_key=${APP_KEY}&q=${query}`;

  const getRecipes = async () => {
    if (!APP_ID || !APP_KEY) {
      console.error('Edamam API credentials are missing');
      return;
    }

    try {
      const response = await fetch(REQUEST_STR);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setRecipes(data.hits);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const updateSearch = e => {
    setSearch(e.target.value);
    console.log(search);
  }

  const getSearch = e => {
    e.preventDefault();
    setQuery(search);
    setSearch("")
  }

  // Remove the old Google Analytics script injection

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/drinks" exact component={Drinks} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
