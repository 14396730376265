import React, { useState } from 'react';
import './Newsletter.css';
import { API_URL } from './config';

const Newsletter = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const submitNewsletter = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/v1/newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    name: name
                })
            });

            if (!response.ok) {
                throw new Error('Failed to submit newsletter');
            }

            const responseData = await response.json();
            console.log('Success:', responseData);
            alert('Thank you for subscribing!');
        } catch (error) {
            console.error('Error submitting newsletter:', error);
            alert('Error submitting the form. Please try again.');
        }
    };

    return (
        <div className="newsletter">
            <form onSubmit={submitNewsletter}>
                <h2>Sign-up to the CuisineSavvy Newsletter!</h2>
                <div className="input-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>
        </div>
    );
};

export default Newsletter;
