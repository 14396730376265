const config = {
  APP_ID: process.env.REACT_APP_EDAMAM_APP_ID || '900da95e',
  APP_KEY: process.env.REACT_APP_EDAMAM_APP_KEY || '40698503668e0bb3897581f4766d77f9',
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'G-2LX8DFQYPN'
};

// Export the variables as named exports
export const API_URL = process.env.REACT_APP_API_URL || 'https://cs.ldjltd.com';
export const BROWSE_APP_URL = process.env.REACT_APP_BROWSE_APP_URL || 'https://app.cuisinesavvy.com';
export const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL || 'https://cuisinesavvy-env1-images.s3.eu-north-1.amazonaws.com';
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID ||'G-2LX8DFQYPN';

// You can also include a default export if needed
export default {
  API_URL,
  BROWSE_APP_URL,
  S3_BASE_URL,
  GA_MEASUREMENT_ID
};