// src/Header.js
import React from 'react';
import './Header.css';
import logo from './assets/logo.jpg';
import { BROWSE_APP_URL } from './config';

const Header = () => {
    return (
        <header className="shadow-sm bg-white">
            <nav className="container mx-auto p-4 flex justify-between items-center bg-white">
                <a href={`${BROWSE_APP_URL}/`} className="logo">
                    <img src={logo} alt="CuisineSavvy Logo" className="header-logo" />
                </a>
                <ul className="flex gap-4 items-center text-black mx-auto text-lg font-semibold">
                    <li>
                        <a href={`${BROWSE_APP_URL}/about`}>About</a>
                    </li>
                    <li>
                        <a href={`${BROWSE_APP_URL}/recipe`}>Recipes</a>
                    </li>
                    <li>
                        <a href={`${BROWSE_APP_URL}/drink`}>Drinks</a>
                    </li>
                    <li>
                        <a href={`${BROWSE_APP_URL}/nutrition`}>Nutrition</a>
                    </li>
                    <li>
                        <a href={`${BROWSE_APP_URL}/blog`}>Blog</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
