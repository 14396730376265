import React, { useState } from 'react';
import Modal from 'react-modal';
import style from './recipeModal.module.css';
import { API_URL } from './config';

Modal.setAppElement('#root'); // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

const RecipeModal = ({ isOpen, onRequestClose, recipe }) => {
    const [notes, setNotes] = useState('');
    const [editingIngredients, setEditingIngredients] = useState(false);
    const [ingredients, setIngredients] = useState([...recipe.ingredientLines]);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setEmail(emailValue);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(emailRegex.test(emailValue));
    };

    const handleSubmit = () => {
        // Prepare the data to be sent to the API
        const data = {
            recipeLabel: recipe.label,
            ingredients: ingredients,
            notes: notes,
            url: recipe.url,
            email: email,
        };

        // Send the data using a POST request
        fetch(`${API_URL}/v1/recipe-request`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            setConfirmation(true); // Show confirmation page
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleIngredientChange = (index, event) => {
        const newIngredients = ingredients.slice();
        newIngredients[index] = event.target.value;
        setIngredients(newIngredients);
    };

    const handleAddIngredient = () => {
        setIngredients([...ingredients, '']);
    };

    const handleDeleteIngredient = (index) => {
        const newIngredients = ingredients.filter((_, idx) => idx !== index);
        setIngredients(newIngredients);
    };

    const renderForm = () => (
        <div className={style.recipeCard}>
            <h2>{recipe.label}</h2>
            <img src={recipe.image} alt={recipe.label} />
            <h2>Ingredients</h2>
            {editingIngredients ? (
                <div>
                    {ingredients.map((ingredient, index) => (
                        <div key={index} className={style.ingredientRow}>
                            <input
                                type="text"
                                value={ingredient}
                                onChange={(event) => handleIngredientChange(index, event)}
                                className={style.ingredientInput}
                            />
                            <button
                                onClick={() => handleDeleteIngredient(index)}
                                className={style.deleteButton}
                            >
                                X
                            </button>
                        </div>
                    ))}
                    <button onClick={handleAddIngredient} className={style.addButton}>
                        Add Ingredient
                    </button>
                </div>
            ) : (
                <ul>
                    {ingredients.map((item, idx) => (
                        <li key={idx}>{item}</li>
                    ))}
                </ul>
            )}
            <button onClick={() => setEditingIngredients(!editingIngredients)} className={style.editButton}>
                {editingIngredients ? 'Save Ingredients' : 'Edit Ingredients'}
            </button>
            <h2>Notes</h2>
            <textarea
                value={notes}
                onChange={handleNotesChange}
                placeholder="Write your notes here..."
                className={style.textarea}
            />
            <h2>Email</h2>
            <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className={style.emailInput}
            />
            <button 
                onClick={handleSubmit} 
                className={style.submitButton} 
                disabled={!emailValid || !email}
            >
                Submit
            </button>
            <h2>Original Recipe</h2>
            <a href={recipe.url} target="_blank" rel="noopener noreferrer">{recipe.source}</a>
        </div>
    );

    const renderConfirmation = () => (
        <div className={style.confirmation}>
            <h2>Your Health Score will be calculated</h2>
            <p>After we have reviewed your request we will notify you via the email provided.</p>
            <button onClick={onRequestClose} className={style.closeButton}>Close</button>
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Recipe Modal"
            className={style.modal}
            overlayClassName={style.overlay}
        >
            <div className={style.modalContent}>
                {confirmation ? renderConfirmation() : renderForm()}
            </div>
        </Modal>
    );
}

export default RecipeModal;
