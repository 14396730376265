import React, { useState, useEffect } from 'react';
import './App.js';
import './Home.css';
import SimpleRecipeList from './SimpleRecipeList.js';
import Header from './Header.js';
import Newsletter from './Newsletter';
import { useLocation } from 'react-router-dom';
import config from './config'
import { API_URL, BROWSE_APP_URL, S3_BASE_URL} from './config';

const Home = () => {
    const [recipes, setRecipes] = useState(null);
    const [error, setError] = useState(null);
    const [query, setQuery] = useState('');
    const [featuredRecipe, setFeaturedRecipe] = useState(null);
    const [recentRecipes, setRecentRecipes] = useState([]);

    const location = useLocation();

    const fetchRecipes = (searchQuery) => {
        if (!searchQuery.trim()) {
            console.log('Search query is empty. Fetching homepage data instead.');
            fetchHomepageData();
            return;
        }

        const APP_ID = config.APP_ID || '900da95e'
        const APP_KEY = config.APP_KEY || '40698503668e0bb3897581f4766d77f9'
        console.log('fetching results for q:' + searchQuery);
        fetch(`https://api.edamam.com/search?app_id=${APP_ID}&app_key=${APP_KEY}&q=${encodeURIComponent(searchQuery)}`)
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(data => {
                console.log('Fetched data:', data);
                setRecipes(data.hits);
            })
            .catch(err => {
                setError(err.message);
                console.error('Fetch error:', err);
            });
    };

    const fetchHomepageData = () => {
        const RECIPE_SERVICE_URL = config.API_URL || 'https://cs.ldjltd.com'
        console.log('RECIPE_SERVICE_URL:' + RECIPE_SERVICE_URL)
        fetch(`${RECIPE_SERVICE_URL}/homepage`)
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(data => {
                console.log('Homepage data:', data);
                setFeaturedRecipe(data.featured);
                setRecentRecipes(data.recent);
            })
            .catch(err => {
                setError(err.message);
                console.error('Fetch error:', err);
            });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const q = params.get('q');
        if (q) {
            setQuery(q);
            fetchRecipes(q);
        } else {
            fetchHomepageData();
        }
    }, [location.search]);

    useEffect(() => {
        document.title = "CuisineSavvy Home";
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && query) {
            fetchRecipes(query);
        }
    };

    const handleSearchClick = () => {
        if (query) {
            fetchRecipes(query);
        }
    };

    return (
        <div className="home">
            <Header showSearch={false} /> {/* Update this line */}
            <div className="search-container">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Search For Recipe"
                    className="large-search-input"
                    style={{ border: '1px solid black' }} // Added this line
                />
                <button 
                    onClick={handleSearchClick} 
                    className="large-search-button"
                >
                    Search
                </button>
            </div>
            
            {query === '' && (
                <div className="default-view">
                    <div className="featured-content">
                        {featuredRecipe && (
                            <div className="featured-wrapper">
                                <h2 className="featured-title">Featured</h2>
                                <div className="featured-image-wrapper">
                                    <a href={`${BROWSE_APP_URL}/recipe/${featuredRecipe.identifier}`}>
                                        <img 
                                            src={`${S3_BASE_URL}/recipe/${featuredRecipe.identifier}.jpg`} 
                                            alt={featuredRecipe.title} 
                                            className="featured-image" 
                                        />
                                    </a>
                                </div>
                                <p className="featured-recipe-title">{featuredRecipe.title || 'Loading...'}</p>
                                <p className="featured-description">
                                    {featuredRecipe.description.length > 120
                                        ? `${featuredRecipe.description.substring(0, 120)}...`
                                        : featuredRecipe.description}
                                </p>
                            </div>
                        )}
                        <Newsletter />
                    </div>
                    
                    <section className="recent-recipes">
                        <h2>Recent Recipes</h2>
                        <div className="recent-recipes-list">
                            {recentRecipes.map((recipe, index) => (
                                <div className="recipe-item" key={index}>
                                    <a href={`${BROWSE_APP_URL}/recipe/${recipe.identifier}`}>
                                        <img 
                                            src={`${S3_BASE_URL}/recipe/${recipe.identifier}.jpg`} 
                                            alt={recipe.title} 
                                            className="recipe-image" 
                                        />
                                    </a>
                                    <p>{recipe.title}</p>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            )}
            {query !== '' && (
                <div className="content">
                    {error && <p>Error: {error}</p>}
                    {recipes && <SimpleRecipeList recipes={recipes} />}
                </div>
            )}
            
            {/* Add the footer here */}
            <footer className="footer">
                <p>CuisineSavvy &copy; 2023 | verion 0.1.1</p>
            </footer>
        </div>
    );
}

export default Home;
